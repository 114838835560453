






































import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppCountdown from "@/components/core/AppCountdown.vue";
import AppInput from "@/components/core/AppInput.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { createSeries } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppCountdown,
    AppInput,
    AppLabel,
    AppWidget,
  },
})
export default class AppCreateSeriesWidget extends Vue {
  @Prop({ required: true })
  readonly catalogId!: pro.Id;

  notification: Notification | null = null;
  saving = false;
  series: pro.Series = {
    brand: "",
    name: "",
  };

  mounted() {
    this.series = { ...this.series, catalog: { id: this.catalogId } };
  }

  /**
   * Creates a new product series
   */
  async createSeries(): Promise<void> {
    this.saving = true;
    this.notification = null;

    try {
      const series = await createSeries(this.series);
      this.goToSeries(series);
    } catch (err) {
      this.notification = createErrorNotification(
        "Unable to create product series",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }

  goToSeries(series: pro.Series) {
    this.$router.push({
      name: "SERIES",
      params: {
        catalogId: String(this.catalogId),
        seriesId: String(series.id),
      },
    });
  }
}
