










































import { Component, Prop, Vue } from "vue-property-decorator";
import AppAlert from "@/components/core/AppAlert.vue";
import AppButton from "@/components/core/AppButton.vue";
import AppCountdown from "@/components/core/AppCountdown.vue";
import AppLabel from "@/components/core/AppLabel.vue";
import AppModal from "@/components/core/AppModal.vue";
import { updateCatalog } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppAlert,
    AppButton,
    AppCountdown,
    AppLabel,
    AppModal,
  },
})
export default class AppRenameCatalogModal extends Vue {
  @Prop({ required: true })
  readonly initialCatalog!: pro.Catalog;

  catalogNameCharRange = [2, 50];
  catalog: pro.Catalog | null = null;
  notification: Notification | null = null;
  saving = false;

  mounted() {
    this.catalog = { ...this.initialCatalog };
  }

  async renameCatalog(): Promise<void> {
    if (!this.catalog) return;
    try {
      this.saving = true;
      await updateCatalog({ id: this.catalog.id, name: this.catalog.name });
      this.$emit("renamed", this.catalog.name);
      this.$emit("close");
    } catch (err) {
      this.notification = createErrorNotification(
        "Unable to rename Catalog",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }
}
