
















































































































































import { Component, Vue } from "vue-property-decorator";
import AppBadge from "@/components/core/AppBadge.vue";
import AppCard from "@/components/core/AppCard.vue";
import AppCardDeck from "@/components/core/AppCardDeck.vue";
import AppCreateSeriesWidget from "@/components/widgets/AppCreateSeriesWidget.vue";
import AppDisableCatalogWidget from "@/components/widgets/AppDisableCatalogWidget.vue";
import AppRenameCatalogModal from "@/components/modals/AppRenameCatalogModal.vue";
import AppSearch from "@/components/core/AppSearch.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheMain from "@/components/TheMain.vue";
import { findCatalog, searchSeries } from "@/utils/api";
import {
  hasAdminPermissions,
  hasApproverPermissions,
  hasMarketerPermissions,
  hasShipperPermissions,
} from "@/utils/authorization";

@Component({
  components: {
    AppBadge,
    AppCard,
    AppCardDeck,
    AppDisableCatalogWidget,
    AppCreateSeriesWidget,
    AppRenameCatalogModal,
    AppSearch,
    AppWidget,
    TheHeader,
    TheMain,
  },
  methods: { searchSeries },
})
export default class Catalog extends Vue {
  catalog: pro.Catalog | null = null;
  isAdmin = false;
  isApprover = false;
  isMarketer = false;
  isShipper = false;
  showRenameCatalogModal = false;

  get catalogId(): pro.Id {
    return Number(this.$route.params.catalogId);
  }

  async mounted() {
    const catalog = { id: this.catalogId };
    this.isMarketer = await hasMarketerPermissions(catalog);
    this.isAdmin = await hasAdminPermissions(catalog);
    this.isShipper = await hasShipperPermissions(catalog);
    this.isApprover = await hasApproverPermissions(catalog);
    await this.loadCatalog();
  }

  async loadCatalog(): Promise<void> {
    this.catalog = await findCatalog({ id: this.catalogId });
  }

  filters() {
    return {
      archived: false,
      ...(!this.isMarketer && { disabled: false }),
    };
  }
}
