






import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class AppCard extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;
}
