





















import { Component, Prop, Vue } from "vue-property-decorator";
import AppButton from "@/components/core/AppButton.vue";
import AppWidget from "@/components/core/AppWidget.vue";
import { updateCatalog } from "@/utils/api";
import { createErrorNotification, Notification } from "@/utils/notification";

@Component({
  components: {
    AppButton,
    AppWidget,
  },
})
export default class AppDisableCatalogWidget extends Vue {
  @Prop({ type: Object, required: true })
  readonly catalog!: pro.Catalog;

  notification: Notification | null = null;
  saving = false;

  /**
   * Disables/Enables a Catalog
   */
  async disableCatalog(disabled: boolean): Promise<void> {
    try {
      this.saving = true;
      this.notification = null;
      await updateCatalog({ id: this.catalog.id, disabled });
      this.catalog.disabled = disabled;
    } catch (err) {
      this.notification = createErrorNotification(
        "Unable to disable catalog",
        err.message
      );
    } finally {
      this.saving = false;
    }
  }

  currentState() {
    return this.catalog.disabled ? "Enable" : "Disable";
  }
}
